var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"ma-1 pa-1"},[_c('v-card-title',{staticClass:"pa-1"},[_c('span',[_vm._v(_vm._s(_vm.$t("user.online")))]),_c('v-spacer')],1),_c('v-data-table',{attrs:{"dense":"","fixed-header":"","height":_vm.screenHeight,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.users,"footer-props":{
        itemsPerPageOptions: [50, 100, 200, -1],
        itemsPerPageAllText: _vm.$t('itemsPerPageAllText'),
        itemsPerPageText: _vm.$t('itemsPerPageText'),
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right'
      }},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.users .map(function(x) { return x.id; }) .indexOf(item.id) + 1)+" ")]}},{key:"item.employee",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.employee["firstname_" + _vm.language] + " " + item.employee["lastname_" + _vm.language] + " " + item.employee["middlename_" + _vm.language])+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }